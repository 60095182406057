@box-bg: rgb(13,46,68);
body.login {
    height: 100%;
    width: 100%;
    background-image:  url('@{variable-img-url}/loginbg.jpg');
    background-position:  center;
    background-repeat: no-repeat;
    background-size:  cover;
    background-color: #E6EDF2;
    border-top: 0;

    &:after{
        content: '';
        display: inline-block;
        background-image: url('@{variable-img-url}/loginbglogo.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: auto;

        height: 144px;
        width: 221px;
        position: fixed;
        bottom: 0;
        right: 0;
        cursor: pointer;
    }

    .login-modal{
        @width: 380px;
        @height: 250px;
        width: @width;
        min-height: @height;
        margin-top: -(@height/2) - 80px;
        margin-left: -(@width/2);
        
        position: fixed;
        top: 50%;
        left: 50%;
        .login-box{
            padding: 0px 20px 30px;
            background: @box-bg;
            color: @sec-color;
            .pageTitle{
                @height: 140px;
                line-height: @height;
                border-bottom: 1px dashed rgba(0,0,0,0.1);
                overflow: hidden;
                padding: 0 20px;
                margin:0  -20px 0px;
                position: relative;
                vertical-align: middle;
                .login-logo{
                    width: 100%;
                    height: @height;
                    line-height: @height;
                    background: url('@{variable-img-url}/logologin.png') no-repeat center;
                    background-size: auto 60%;
                    float: left;
                }

                .header{
                    display: none;
                    height: @height;
                    line-height: @height;
                    padding: 0 30px;
                    background: @base-color;
                    color: #fff;
                    position: absolute;
                    right: 0;
                    top: -20px;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }
            #authorization{
                margin-bottom: 10px;
            }
            .cookie-link{
                list-style: none;
                margin: 20px 0 10px;
                padding: 0;
                li{
                  display: inline-block;
                  margin-right: 30px;
              }
          }
          hr{
              border-color: rgba(255, 255, 255, 0.1);
          }
          .text-info{
              color: @base-color;
          }
      }

      .login-form{
        .form-group{
            padding-right: 0px;
            input.form-control{
                @height: 36px;
                height: @height;
                line-height: @height;
                padding: 0px 10px;
                -webkit-appearance: none;
                outline:none;
                border-radius: 0;
                background-color: transparent;
                background-image: none;
                border: 2px solid @sec-color;
                color: @base-color;
                &:focus{
                    box-shadow: none;
                    border: 1px solid #ff6694;
                }
            }
            .field-validation-error{
                margin-top: 4px;
                display: block;
            }
        }
        .btn{
            @height: 40px;
            height: @height;
            line-height: @height;
            padding: 0 25px;
            [class^="fa"]{
                margin:0 0 0 5px;
                height: @height;
                line-height: @height;
            }
        }
    }
    .language-row{
        margin-bottom: 15px;
        height: 30px;
        .form-control{
            height: auto;
        }
    }
}
.validation-summary-errors {
    padding: 20px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: darken(@danger-color, 30%);
    margin-bottom: 10px;

    ul {
        margin: 0px;
        padding: 0;
        list-style: none;;
    }
}

.field-validation-error {
    color: fade(@danger-color, 60%);
}
.version{
    color: @sec-color;
}
}
